/* MapComponent.css */
.map-container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
    background-color: #1ba459;
}

.map {
    width: 4096px;
    height: 2731px;
    position: absolute;
}

/* Debug overlay */
.debug-overlay {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
    z-index: 1000;
    max-height: 80vh;
    overflow-y: auto;
}

/* Recenter button */
.recenter-button {
    position: absolute;
    bottom: 70px;
    left: 0px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 0px;
    border-radius: 5px;
    font-size: 16px;
    z-index: 1001;
    max-height: 80vh;
    overflow-y: auto;
    cursor: pointer;
}

/* Player Icon */
.player-icon {
    position: absolute;
    /*top: 50%;      /* Center vertically */
    /*left: 50%;     /* Center horizontally */
    z-index: 900;
}

/* Range Indicator */
.range-indicator {
    position: absolute;
    /*width: 300px; /* Range diameter, adjust as needed */
    /*height: 300px;*/
    border-radius: 50%;
    background-color: rgba(0, 123, 255, 0.1); /* Light blue, semi-transparent */
    border: 1px dashed rgba(0, 123, 255, 0.6); /* Optional border */
    pointer-events: none; /* So it doesn't block clicks */
    z-index: 850;
  }
  