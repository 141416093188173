* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app-container {
  height: 100vh;
  position: relative;
  text-align: center;
  font-family: 'Oswald', 'Arial', sans-serif;
}

body {
  height: 100%;
  overflow: hidden;
  margin: 0;
  background-color: rgb(255, 255, 255); /* Temporary test */
  font-family: 'Oswald', 'Arial', sans-serif;
}

.splash-image {
  width: 100vw;
  height: 100vh;
  object-fit: cover; /* or "contain" if you want to show the full image */
  display: block;
}

.init-options {
  display: flex;
  position: fixed;
  transform: translate(-50%, -50%); /* Offset by half its size */
  top: 70%;
  left: 50%;
  z-index: 1003;
}

.player-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* optional: aligns text to the left */
  gap: 2px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f8f9facc;
  border-top: 1px solid #ccc;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1002
}

.status {
  height: 100%;
  margin-bottom: 0px;
  background-color: #f0f0f0;
  border-radius: 8px;
}

.destinations-button-group {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1200;
}

.destinations-button {
  top: 0;
  right: 0;
  margin: 10px; /* distance from edges */
  width: 80px;
  height: 80px;
  padding: 25px 5px;
  font-size: 24px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  background-color: #ffffff;
  color: white;
}

.button {
  margin: 20px;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
}

.destination-group {
  display: flex;
  position: fixed;
  top: 30%;
}

.destination-selection {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  max-height: 300px; /* Set a limit to height */
  overflow-y: auto;
  padding-bottom: 100px; /* Add enough padding to avoid the buttons */
}

.destinations button:hover {
  background-color: #45a049;
}

.message {
  margin-top: 20px;
  padding: 15px;
  border-radius: 6px;
  background-color: #dff0d8;
  color: #3c763d;
}

.modal-overlay {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background-color: #fff;
  padding: 1.0rem;
  border-radius: 1rem;
  max-width: 400px;
  width: 50%;
  text-align: center;
}

.shop-menu {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(239, 255, 167);
  display: flex;
  justify-content: center;
  align-items: center;
  /*z-index: 9998;*/
}

.bottom-buttons {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-evenly;
  background-color: #f8f9fa;
  border-top: 1px solid #ccc;
  z-index: 9999;
}

.bottom-buttons button {
  flex: 1; /* Equal width distribution */
  padding: 25px 5px;
  border: 0px 1px;
  border-color: #767676;
  background-color: #000000;
  color: #fff;
  /*border-radius: 5px;*/
  cursor: pointer;
  font-weight: bold;
}

.bottom-buttons button:hover {
  background-color: #000000;
}

.bottom-buttons button:enabled {
  background-color: #000000;
}

.travel-menu {
  padding-bottom: 70px; /* Add enough padding to avoid the buttons */
}

.city-menu {
  position: fixed;
  top: 30%;
}

.trip-timer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center;
  align-items: center;
}

@keyframes moveRight {
  from {
    left: 20%;
  }
  to {
    left: 80%;
  }
}

.zeppelin-traveling {
  position: absolute;
  top: 25%;
  /* animation: moveRight 10s ease-in-out forwards; */
}
