/* ProfileScreen.css */
.profile-container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
    background-color: #bbefff;
}


